import Glide from '@glidejs/glide';
import Helpers from '../lib/helpers';

class GallerySlider {
  constructor({ selector }) {
    this.selector = document.querySelector(selector);

    if (!this.selector) { return null; }

    this.gallerySlider(this.selector);
  }

  gallerySlider(selector) {
    const slides = document.querySelectorAll('[data-js="gallery-slides"]');

    Helpers.forEach(slides, (el) => {
      // Add nav bullets in before we mount so they have event listeners attached
      const glideTrack = el.querySelector('[data-glide-el="track"]');
      let bulletNav = el.querySelector('[data-glide-el="controls[nav]"]');

      // Add a nav bullet inside the container for each .glide__slide
      for (let i = 0; i < glideTrack.firstElementChild.childElementCount; i += 1) {
        bulletNav.innerHTML
          += `<button class="glide__bullet" data-glide-dir="=${[i]}"></button>`;
      }

      new Glide(el, {
        type: 'carousel',
        gap: 0,
        animationDuration: 1000,
        autoplay: false,
        perView: 1,
        autoheight: true,
      }).mount();

      // Immmediately move the arrows / bullets
      this.calculateNewArrowPosition(el);

      // And run again on resize
      window.addEventListener('resize', () => {
        this.calculateNewArrowPosition(el);
      }, { passive: true });
    }, this);
  }

  calculateNewArrowPosition(el) {
    // Set up an empty array for the caption heights
    let captionHeights = [];

    // select all gallery captions for this gallery
    Helpers.forEach(el.querySelectorAll('[data-js="gallery-caption"]'), (el) => {
      // Find the the current caption height
      const thisCaptionHeight = el.getBoundingClientRect().height;

      // Push the current caption Height to an array so we can access it later
      captionHeights.push(thisCaptionHeight);
    }, this);

    // Find the highest value in the array
    const max = captionHeights.reduce((a, b) => {
      return Math.max(a, b);
    });

    // Move the arrows to the bottom right of the image
    let controls = el.querySelector('[data-glide-el="controls"]');
    const distancePx = `${max}px`;
    controls.style.bottom = distancePx;

    // select the bullet container
    let bulletNav = el.querySelector('[data-glide-el="controls[nav]"]');

    // Move the bullets to 30px from the bottom of the image
    const bulletDistancePx = max + 30;
    bulletNav.style.top = `calc(100% - ${bulletDistancePx}px)`;
  }
}

export default GallerySlider;
