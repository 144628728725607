import Helpers from '../lib/helpers';

class Search {
  constructor({ searchContainerSelector, searchButtonsSelector, searchBarSelector }) {
    this.searchContainerSelector = searchContainerSelector;
    this.searchButtonsSelector = searchButtonsSelector;
    this.searchBarSelector = searchBarSelector;

    if (!this.searchContainerSelector) { return null; }
    if (!this.searchButtonsSelector) { return null; }
    if (!this.searchBarSelector) { return null; }

    // Show / hide the search bar
    const searchContainers = document.querySelectorAll(this.searchContainerSelector);
    const searchButtons = document.querySelectorAll(this.searchButtonsSelector);

    if (!searchContainers) { return null; }
    if (!searchButtons) { return null; }

    Helpers.forEach(searchContainers, (el) => {
      const searchContainer = el;
      const searchBtn = searchContainer.querySelector('[data-search]');
      const searchBar = searchContainer.querySelector('[data-search-box]');
      const searchInput = searchContainer.querySelector('input[type="search"]');

      searchBtn.addEventListener('click', (e) => {
        e.preventDefault();
        Search.toggleSearchVisibility(searchContainer, searchBar);
      });

      // Close Search when clicking outside it
      window.addEventListener('click', (event) => {
        if (!searchContainer.contains(event.target)) {
          // console.log("searchContainer DOES NOT contain the click target");
          if (searchBar) {
            if (searchBar.getAttribute('data-visible') != null) {
              searchBar.removeAttribute('data-visible');
              searchContainer.setAttribute('aria-expanded', 'false');
              searchContainer.removeAttribute('data-visible');
            }
          }
        }
      });
    });
  }

  static toggleSearchVisibility(searchContainer, searchBar) {
    if (searchBar) {
      if (searchBar.getAttribute('data-visible') != null) {
        searchBar.removeAttribute('data-visible');
        searchContainer.setAttribute('aria-expanded', 'false');
        searchContainer.removeAttribute('data-visible');
      } else {
        searchBar.setAttribute('data-visible', '');
        searchContainer.setAttribute('data-visible', '');
        searchContainer.setAttribute('aria-expanded', 'true');
        document.getElementById('q').focus();
      }
    }
  }
}

export default Search;
