import Helpers from '../lib/helpers';

class LanguagePicker {
  constructor() {
    // Return the index of an element in a node array
    function getIndexInArray(array, el) {
      return Array.prototype.indexOf.call(array, el);
    }

    // Move focus to an element
    function moveFocus(el) {
      let element = el;
      if (!element) {
        element = document.getElementsByTagName("body")[0];
      }
      element.focus();
      if (document.activeElement !== element) {
        element.setAttribute('tabindex', '-1');
        element.focus();
      }
    }

    const LanguagePicker = function(element) {
      this.element = element;
      this.select = this.element.getElementsByTagName('select')[0];
      this.options = this.select.getElementsByTagName('option');
      this.selectedOption = getSelectedOptionText(this);
      this.pickerId = this.select.getAttribute('id');
      this.trigger = false;
      this.dropdown = false;
      this.firstLanguage = false;

      // Create the custom button and list elements
      initLanguagePicker(this);

      // Add Event listeners
      initLanguagePickerEvents(this);
    };

    function initLanguagePicker(picker) {
      // Create the HTML for the custom dropdown element
      picker.element.insertAdjacentHTML('beforeend', initButtonPicker(picker) + initListPicker(picker));

      // Save picker elements
      picker.dropdown = picker.element.getElementsByClassName('language-picker__dropdown')[0];
      picker.languages = picker.dropdown.getElementsByClassName('language-picker__item');
      picker.firstLanguage = picker.languages[0];
      picker.trigger = picker.element.getElementsByClassName('language-picker__button')[0];
    }

    // Add Event listeners to the top button
    // and to keyboard up/down arrows
    function initLanguagePickerEvents(picker) {
      // Language selection in dropdown
      // ⚠️ Important: you need to modify this function in production

      // Picker = the original select box that we hide
      initLanguageSelection(picker);

      // Click events
      picker.trigger.addEventListener('click', () => {
        toggleLanguagePicker(picker, false);
      });

      // Keyboard navigation
      picker.dropdown.addEventListener('keydown', (event) => {
        // If hit arrow up
        if (event.keyCode && event.keyCode == 38 || event.key && event.key.toLowerCase() == 'arrowup') {
          event.preventDefault;
          keyboardNavigatePicker(picker, 'prev');
        // If hit arrow down
        } else if(event.keyCode && event.keyCode == 40 || event.key && event.key.toLowerCase() == 'arrowdown') {
          event.preventDefault;
          keyboardNavigatePicker(picker, 'next');
        }
      });
    }

    function toggleLanguagePicker(picker, bool) {
      let ariaExpanded;
      if (bool) {
        ariaExpanded = bool;
      } else {
        ariaExpanded = picker.trigger.getAttribute('aria-expanded') === 'true' ? 'false' : 'true';
      }
      picker.trigger.setAttribute('aria-expanded', ariaExpanded);
      if (ariaExpanded === 'true') {
        picker.firstLanguage.focus(); // fallback if transition is not supported
        picker.dropdown.addEventListener('transitionend', function cb() {
          picker.firstLanguage.focus();
          picker.dropdown.removeEventListener('transitionend', cb);
        });
        // Place dropdown
        placeDropdown(picker);
      }
    }

    function placeDropdown(picker) {
      const triggerBoundingRect = picker.trigger.getBoundingClientRect();

      if (window.innerWidth < triggerBoundingRect.left + picker.dropdown.offsetWidth) {
        Helpers.toggleClass(picker.dropdown, 'language-picker__dropdown--right');
      }
      if (window.innerHeight < triggerBoundingRect.bottom + picker.dropdown.offsetHeight) {
        Helpers.toggleClass(picker.dropdown, 'language-picker__dropdown--up');
      }
    }

    // If user clicks outside the language picker -> close it
    function checkLanguagePickerClick(picker, target) {
      if (!picker.element.contains(target)) toggleLanguagePicker(picker, 'false');
    }

    function moveFocusToPickerTrigger(picker) {
      if (picker.trigger.getAttribute('aria-expanded') === 'false') return;
      if (document.activeElement.closest('.language-picker__dropdown') === picker.dropdown) picker.trigger.focus();
    }

    /////////////////////////////////////////////////////
    // Create HTML markup for the custom button element
    /////////////////////////////////////////////////////
    function initButtonPicker(picker) {
      // Check if we need to add custom classes to the button trigger
      const customClasses = picker.element.getAttribute('data-trigger-class') ? ` ${picker.element.getAttribute('data-trigger-class')}` : '';

      let button = '<button class="language-picker__button'
      + customClasses
      + '" aria-label="'
      + picker.select.value
      + ' '
      + picker.element.getElementsByTagName('label')[0].textContent
      + '" aria-expanded="false" aria-controls="'
      + picker.pickerId
      + '-dropdown">';

      if (customClasses == ' btn js-tab-country') {
        button = button + '<span aria-hidden="true" class="language-picker__label country-picker__label language-picker__flag language-picker__flag--'
        + picker.select.value+'">'
        + '<span class="language-picker__current-language country-picker__current-country">'
        + picker.selectedOption
        + '</span>'
        + '</span>';
      }
      else {
        button = button + '<span aria-hidden="true" class="language-picker__label language-picker__flag language-picker__flag--'
        + picker.select.value+'">'
        + '<span class="language-picker__current-language">'
        + picker.selectedOption
        + '</span>'
        + '</span>';
      }

      return button + '</button>';
    }

    //////////////////////////////////////////////////
    // Create HTML markup for language picker dropdown
    //////////////////////////////////////////////////
    function initListPicker(picker) {
      var list = '<div class="language-picker__dropdown" aria-describedby="'+picker.pickerId+'-description" id="'+picker.pickerId+'-dropdown">';
      list = list + '<p class="visually-hidden" id="'+picker.pickerId+'-description">'+picker.element.getElementsByTagName('label')[0].textContent+'</p>';
      list = list + '<ul class="language-picker__list" role="listbox">';
      for (var i = 0; i < picker.options.length; i++) {
        var selected = picker.options[i].hasAttribute('selected') ? ' aria-selected="true"' : '',
          language = picker.options[i].getAttribute('lang');
        list = list + '<li><a lang="'
        + language
        + '" hreflang="'
        + language
        + '" href="' + picker.options[i].value + '"'
        + selected
        + ' role="option" data-value="'
        + picker.options[i].value
        + '" class="language-picker__item language-picker__flag language-picker__flag--'
        + picker.options[i].value
        + '"><span>'
        + picker.options[i].text
        + '</span></a></li>';
      }
      return list;
    }

    //////////////////////////////////////////////////////////
    // Add the initial label of the picker trigger button
    // Taken from the native select box that we hide
    //////////////////////////////////////////////////////////
    function getSelectedOptionText(picker) {
      let label = '';
      if ('selectedIndex' in picker.select) {
        label = picker.options[picker.select.selectedIndex].text;
      } else {
        label = picker.select.querySelector('option[selected]').text;
      }
      return label;
    }

    function getLanguageUrl(option) {
      // ⚠️ Important: You should replace this return value with the real link to your website in the selected language
      // option.value gives you the value of the language that you can use to create your real url (e.g, 'english' or 'italiano')
      let languageUrl;

      if (option.value === 'deutsch') {
        languageUrl = '/de';
        return languageUrl;
      }
      // return '#';
    }

    ////////////////////////////////////////////////////////////
    // Add event listeners to the language list items
    ////////////////////////////////////////////////////////////
    function initLanguageSelection(picker) {
      // On click of the custom language list
      picker.element.getElementsByClassName('language-picker__list')[0].addEventListener('click', function(event){
        // Select language nearest to click point
        var language = event.target.closest('.language-picker__item');
        if (!language) return;

        // If we just clicked the currently active language option..
        // Prevent the default <a> tag behaviour and hide the dropdown list
        if (language.hasAttribute('aria-selected') && language.getAttribute('aria-selected') == 'true') {
          // Selecting the same language
          event.preventDefault();
          picker.trigger.setAttribute('aria-expanded', 'false'); // hide dropdown
        } else {
          // New language option selected, take user to the language link's URL

          // ⚠️ Important: this 'else' code needs to be removed in production.
          // The user has to be redirected to the new url -> nothing to do here

          // event.preventDefault();
          picker.element.getElementsByClassName('language-picker__list')[0].querySelector('[aria-selected="true"]').removeAttribute('aria-selected');
          language.setAttribute('aria-selected', 'true');
          picker.trigger.getElementsByClassName('language-picker__label')[0].setAttribute('class', 'language-picker__label language-picker__flag language-picker__flag--'+language.getAttribute('data-value'));
          picker.trigger.getElementsByClassName('language-picker__label')[0].getElementsByClassName('language-picker__current-language')[0].textContent = language.textContent;
          picker.trigger.setAttribute('aria-expanded', 'false');
        }
      });
    }

    ///////////////////////////////////////////////////////////////////
    // Move focus to correct list item on up/down keyboard arrow press
    ///////////////////////////////////////////////////////////////////
    function keyboardNavigatePicker(picker, direction) {
      // Get index of currently focused element
      let index = getIndexInArray(picker.languages, document.activeElement);
      // If direction = next add 1 to current index
      // Else direction is prev so minus 1 from current index
      index = (direction === 'next') ? index + 1 : index - 1;
      if (index < 0) index = picker.languages.length - 1;
      if (index >= picker.languages.length) index = 0;
      // Move focus to correct list item
      moveFocus(picker.languages[index]);
    }

    ////////////////////////////////////////////////////////////////////////
    // 1. Initialize all the LanguagePicker objects
    // 2. Loop through all language pickers on the page so we can...
    // attach events listeners to close each one on 'ESC' or a click outside
    ////////////////////////////////////////////////////////////////////////
    const languagePicker = document.getElementsByClassName('language-picker');
    // const languagePickers = document.querySelectorAll('[data-trigger-class]');
    // console.log("languagePicker = ", languagePicker);
    // console.log("languagePickers = ", languagePickers);
    if (languagePicker.length > 0) {
      const pickerArray = [];
      for (let i = 0; i < languagePicker.length; i++) {
        (function(i){ pickerArray.push(new LanguagePicker(languagePicker[i])); })(i);
      }

      // Close language picker on 'Esc'
      window.addEventListener('keyup', function(event) {
        if (event.keyCode && event.keyCode == 27 || event.key && event.key.toLowerCase() == 'escape') {
          pickerArray.forEach(function(element) {
            moveFocusToPickerTrigger(element); // If focus is within dropdown, move it to dropdown trigger
            toggleLanguagePicker(element, 'false'); // Close dropdown
          });
        }
      });

      // Close language picker when clicking outside it
      window.addEventListener('click', function(event){
        pickerArray.forEach(function(element){
          checkLanguagePickerClick(element, event.target);
        });
      });
    }
  }
}

export default LanguagePicker;
